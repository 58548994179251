import logo from './logo.svg';
import './App.css';
import AppRouter from './navigation';
import { BrowserRouter } from "react-router-dom";

import { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import FlashProvider from './context';
import NetworkProvider from './context/network_provider';
import 'react-toastify/dist/ReactToastify.css';


function App() {
  return (
    <FlashProvider>
      <BrowserRouter>
        <NetworkProvider>
          <AppRouter />
          <ToastContainer />
        </NetworkProvider>
      </BrowserRouter >
    </FlashProvider>
  );
}

export default App;
