import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useStake } from "../hooks/useStaking";
import { parseEther } from "../utils";
import { toast } from "react-toastify";
import { useAccount, usePrepareContractWrite, useWaitForTransaction } from "wagmi";
import { Button } from ".";
import { useAllowance } from "../hooks/useApprove";

const StakeButton = ({ amount, bonusOptions, setIsApproved, isApproved }) => {
    const { data: allowanceData, isLoading: allowanceLoading } = useAllowance();

    const stakeCt = useStake([parseEther(amount.toString()), bonusOptions]);

    const stakeWait = useWaitForTransaction({
        hash: stakeCt.data?.hash,
        onSuccess(data) {
            window.location.reload();
        },
        onError(data) {
        }
    })

    const onStake = () => {

        if (bonusOptions == -1) {
            toast.warning("Bonus Lock should be selected!");
            return;
        }
        if (amount === 0) {
            toast.warning("Amount to stake should not be zero!");
            return;
        }

        stakeCt.write && stakeCt.write();
        //   
    }
    return (

        <>
            <Box sx={{ marginTop: "20px" }
            } >
                <Button label="Stake" bg="#FBBF04" color={'black'} onClick={() => {
                    onStake();
                }} />
            </Box >
        </>
    );
};

export default StakeButton;
