import React, { useMemo } from 'react'
import { useState, useEffect } from 'react';
import { Card, Header, Layout, LeftBar } from '../../components';
import imgDemo from '../../assets/images/demo.png';
import Staking from './components/FTTEarned';
import Token from './components/Token';
import { stakingContract } from "../../contracts";
import { useTokenBalance, useTopDashboardInformation } from '../../hooks/useDashbord';
import {
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useAccount, useWaitForTransaction } from "wagmi";
import styles from "./style.module.css";
import { Button } from "../../components";
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { formatEther, parseEther, toBigNum } from '../../utils/index';
import { toast } from "react-toastify";
import { STAKING_CONTRACT_ADDRESS, FTTAddress, getConstant, getPlanMonth } from "../../utils/constant";
import userimg from "../../assets/images/Ellipse 1.png";
import FTTEarned from './components/FTTEarned';
import { useAirDropAmount, useNFTAmount, useNFTClaim, useAirDropAvailable } from '../../hooks/useNFTs';
import { getNFTAmount, nftClaim } from '../../services/getContract';
import { useTestTokenBalance } from '../../hooks/useDashbord';


const Home = () => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const [currentAmount, setCurrentAmount] = useState(0);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [second, setSecond] = useState(0);
    const [timeLeft, setTimeLeft] = useState({});
    const [filter, setFilter] = useState(-1);
    const [remainedTime, setRemainedTime] = useState(0);
    const [totalRewardDistributed, setTotalRewardDistributed] = useState();
    const { open } = useWeb3Modal();
    const { isConnected, address } = useAccount();
    const [isEnabled, setIsEnabled] = useState(false);
    const [profit, setProfit] = useState(0)

    const staking_address = getConstant(STAKING_CONTRACT_ADDRESS);
    const { data: balanceData, isLoading: balanceLoading } = useTokenBalance(address, getConstant(FTTAddress))
    const { data: availableData, isLoading: availableLoading } = useAirDropAvailable()
    const { contractWrite, refetch, isSuccess } = useNFTClaim(availableData?.profit);
    const { data: totalData, isLoading: totalLoading } = useAirDropAmount();
    const [mobile, setMobile] = useState();
    const [claimFlag, setClaimFlag] = useState(false);
    const [isCalled, setIsCalled] = useState(false);

    const [nftAmount, setNFtAmount] = useState(0);
    const [nftAmountLoading, setNftAmountLoading] = useState(true);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setMobile(window.innerWidth);
        })
    })

    useEffect(() => {
        setMobile(window.innerWidth);
    }, [])


    useEffect(() => {
        const claimFTT = async () => {
            if (availableData?.isEnabled == true) {
                console.log("🚀 ~ file: index.js:71 ~ claimFTT ~ availableData?.isEnabled:", availableData?.isEnabled)
                refetch && refetch()
            }
        }
        claimFTT()
    }, [availableData])

    useEffect(() => {
        if (isSuccess && !isCalled) {
            contractWrite.write && contractWrite.write()
            setIsCalled(true)
        }
    }, [isSuccess, claimFlag])

    const claimNFTAirdrop = async () => {
        setClaimFlag(!claimFlag);
        const nftClaimData = {
            address: address
        }
        let res = await nftClaim(nftClaimData);
        if (res.data.success == true) {
            setIsEnabled(true)
            setProfit(res.data.profit)
            toast.warning(res.data.message);
        }
        else {
            toast.warning(res.data.message);
            return;
        }
    }

    useEffect(() => {
        const getNFTAmountOfMine = async () => {
            const nftAmountData = {
                address: address
            }
            let res = await getNFTAmount(nftAmountData);
            if (res.data.success) {
                setNftAmountLoading(false)
                setNFtAmount(res.data.nftNum)
            }
        }
        getNFTAmountOfMine()
    }, [isConnected])

    useEffect(() => {
        const nextMonth = new Date();
        nextMonth.setMonth(nextMonth.getMonth() + 1);
        nextMonth.setDate(1);
        nextMonth.setHours(0, 0, 0, 0);
        const nextMonthTimestamp = Math.floor(nextMonth.getTime());
        setRemainedTime(nextMonthTimestamp - Math.floor(Date.now()));
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (remainedTime > 0) {
                let tmpday = Math.floor(remainedTime / 864e5)
                let tmphour = Math.floor((remainedTime % 864e5) / 36e5)
                let tmpmin = Math.floor(((remainedTime % 864e5) % 36e5) / 6e4)
                let tmpsec = Math.floor(((remainedTime % 864e5) % 36e5) % 6e4 / 1000);
                setDay(tmpday)
                setHour(tmphour)
                setMinute(tmpmin)
                setSecond(tmpsec)
                setRemainedTime(remainedTime - 1000)
            }
        }, 1000);
    });




    return (
        <Layout name="headername">
            <Header mobile={mobile} rightimg={userimg} userid="0x71C76......34Db76F" mainHeading="NFT Airdrop" />
            <Grid container spacing={2} sx={{ marginTop: '15px', display: 'flex', justifyContent: "space-between" }}>
                <Card label="Your Total FTT value" value={`${Math.floor((balanceData?.formatted || 0) * 100) / 100} FTT`} isLoading={balanceLoading} />
                <Card label="Your Airdrop number NFT" value={nftAmount || 0} isLoading={nftAmountLoading} />
                <Card label="Total Claimed for holders" value={`${Math.floor(formatEther(totalData?.totalClaimed || 0) * 100) / 100} FTT`} isLoading={totalLoading} />
            </Grid>
            <Grid
                container sx={{ marginTop: '5px' }}
                spacing={2}>
                <>
                    <Grid item lg={5} xs={12} sx={{ paddingRight: "0px" }}>
                        <div className={styles.ps_container1}>
                            <h4 className='text-white' style={{ fontSize: '20px', textAlign: 'center' }}>Next Claim</h4>
                            <div className={styles.innerBox}>
                                <p className='text-light text-center opacity-50 mb-0'>{weekday[new Date().getDay()]}</p>
                                <p className='text-light text-center mb-0' >{new Date().toLocaleDateString('en-us', options)}</p>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '30px'
                                }}>
                                    <div className={styles.time}>
                                        <h2>{day}</h2>
                                        <p>Day</p>
                                    </div>
                                    <div className={styles.time}>
                                        <h2>{hour}</h2>
                                        <p>Hour</p>
                                    </div>
                                    <div className={styles.time}>
                                        <h2>{minute}</h2>
                                        <p>Mins</p>
                                    </div>
                                    <div className={styles.time}>
                                        <h2>{second}</h2>
                                        <p>Sec</p>
                                    </div>
                                </Box>

                                <div className='my-4'>
                                    <p className='text-white text-center'>
                                        {currentAmount} FTT
                                    </p>
                                </div>


                            </div>

                        </div>
                    </Grid></>
                <FTTEarned totalRewardDistributed={totalRewardDistributed} setFilter={setFilter} filter={filter} />
            </Grid>
            <Grid
                container
                sx={{ marginTop: '20px', gap: { sm: '17px', lg: '0px' }, flexDirection: { sm: 'column-reverse', lg: 'row' } }}
            >
                <>
                    <Grid
                        lg={7}
                        xs={12}
                        className={styles.amount_grid_container}
                    >

                        <div className={styles.amountContainer}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "100%", height: '100px' }} >
                                <Button width={'50%'} label="Claim FTT Token" onClick={() => {
                                    claimNFTAirdrop()
                                }} />
                            </Box>
                            {
                                isConnected ?
                                    <div></div>
                                    :
                                    <Box sx={{ marginTop: "20px" }}>
                                        <Button label="Connect Wallet" bg="#FBBF04" color={'black'} onClick={() => { open() }} />
                                    </Box>
                            }
                        </div >
                    </Grid >
                </>
                <Token />
            </Grid>

        </Layout >
    )
}

export default Home;
