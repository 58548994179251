import { useSwitchNetwork, useNetwork } from 'wagmi'
import { CHANIN, PROJECT_ENVIRONMENT, getConstant } from '../utils/constant';
import { useEffect } from 'react';
import { useAccount } from 'wagmi';

export function useDetectAndSwitchNetwork(callback, setModalOpen) {

    const _chainId = getConstant(CHANIN);
    const { chain, chainId } = useNetwork();
    const { isConnected } = useAccount();
    const { chains, error, isLoading, isSuccess, switchNetwork } = useSwitchNetwork({
        onSuccess(data) {
            setModalOpen(false)
        },
        onError(error) {
            setModalOpen(false)
            throw new Error("switch network error", error)
        },
        chainId: parseInt(_chainId),
        throwForSwitchChainNotSupported: true,
    });
    useEffect(() => {
        setModalOpen(false)
    }, [isSuccess])
    useEffect(() => {
        if (chain?.id !== parseInt(_chainId) && isConnected) {
            callback();
        }
    }, [switchNetwork, chain])
    return switchNetwork

}