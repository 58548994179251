export const PROJECT_ENVIRONMENT = process.env.REACT_APP_ENV || ""


export const STAKING_CONTRACT_ADDRESS = {
    testnet: process.env.REACT_APP_STAKING_TEST || "",
    mainnet: process.env.REACT_APP_STAKING_MAIN || ""
}


export const NFT_CONTRACT_ADDRESS = {
    testnet: process.env.REACT_APP_NFT_ADDRESS_TEST || "",
    mainnet: process.env.REACT_APP_NFT_ADDRESS_MAIN || ""
}

export const NFT_AIRDROP_CONTRACT_ADDRESS = {
    testnet: process.env.REACT_APP_NFT_AIRDROP_ADDRESS_TEST || "",
    mainnet: process.env.REACT_APP_NFT_AIRDROP_ADDRESS_MAIN || ""
}


export const FTTAddress = {
    testnet: process.env.REACT_APP_FTT_TEST || "",
    mainnet: process.env.REACT_APP_FTT_MAIN || ""
}

export const BUSDADRESS = {
    testnet: process.env.REACT_APP_BUSD_TEST || "",
    mainnet: process.env.REACT_APP_BUSD_MAIN || ""
}
export const CHANIN = {
    testnet: process.env.REACT_APP_CHAIN_TEST || "",
    mainnet: process.env.REACT_APP_CHAIN_MAIN || ""
}

export function getConstant(val) {
    if (PROJECT_ENVIRONMENT !== "mainnet" && PROJECT_ENVIRONMENT !== 'testnet') {
        throw new Error("Invalid Environment Error");
    }

    if (val[PROJECT_ENVIRONMENT] === "") {
        throw new Error("Invalid Constant Error");
    }
    return val[PROJECT_ENVIRONMENT]
}

export function getPlanMonth(plan) {
    switch (plan) {
        case 0:
            return "1 Month"
        case 1:
            return "3 Month"
        case 2:
            return "6 Month"
        case 3:
            return "1 year"
        default:
            return ""
    }
}

export function convertPlan(plan) {
    switch (plan) {
        case 0:
            return 30;
        case 1:
            return 90;
        case 2:
            return 180;
        case 3:
            return 365;
        default:
            return ""
    }
}