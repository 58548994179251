import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LeftBar } from '../components';
import { Home, NFT, StackDashboard, Setting, Support, Chatboard, Chatlist } from "../pages"



const AppRouter = () => {

    return (
        <>
            <Routes>

                <Route path='/' element={<Home />} />
                <Route path='/nft' element={<NFT />} />
                {/* <Route path='/Profile' element={<Profile />} /> */}
                {/* <Route path='/Setting' element={<Setting />} /> */}
                {/* <Route path='/Staking' element={<StackDashboard />} /> */}
                {/* <Route path='/Support' element={<Support />} /> */}
                {/* <Route path='/Chatlist' element={<Chatlist />} /> */}
                {/* <Route path='/Chatboard' element={<Chatboard />} /> */}

            </Routes>


        </>
    )
}

export default AppRouter;