import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useWalletDisconnect } from '../../../hooks/useWallet';

import CloseImg from '../../../assets/images/close.png'
import FlashIcon from '../../../assets/images/flash.png'
import NetworkImg from '../../../assets/images/network.png'
import { useNetwork, useSwitchNetwork } from 'wagmi'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 512,
    height: 644,
};

export default function SwitchNetworkModal({ open, setOpen, switchBnbNetwork }) {
    const { chain } = useNetwork()
    const handleClose = () => setOpen(false);
    const { disconnect } = useWalletDisconnect();
    useEffect(() => {
        setOpen(false)
    }, [chain])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={true}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 512,
                    height: 644,
                    outline: 'none',
                }
                }>
                    <Box sx={{
                        display: 'flex',
                        height: "72px",
                        flexDirection: 'row',
                        borderTopLeftRadius: 16,
                        borderTopRightRadius: 16,
                        backgroundColor: "#FBBF04",
                        paddingX: '32px',
                        paddingY: '16px',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: 24,
                            fontStyle: 'normal',
                            fontWeight: '600'
                        }}>
                            You are in wrong Network
                        </Typography>
                        <Button onClick={() => setOpen(false)} sx={{
                            width: '40px',
                            height: "40px",
                            borderRadius: "20px",
                            backgroundColor: '#FFFFFF',
                            opacity: 0.5,
                            minWidth: '40px'
                        }}>
                            <img src={CloseImg} />
                        </Button>
                    </Box>
                    <Box sx={{
                        backgroundColor: '#151103',
                        padding: '32px',
                        borderBottomLeftRadius: '16px',
                        borderBottomRightRadius: '16px',
                    }}>

                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: 20,
                            fontStyle: 'normal',
                            fontWeight: '400',
                            color: 'white'

                        }}>
                            This page is located for {chain?.name}.
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: 20,
                            fontStyle: 'normal',
                            fontWeight: '400',
                            color: 'white',
                            marginTop: '20px'
                        }}>
                            You are under {chain?.name} now, please switch the network to continue
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            marginTop: '50px',
                            justifyContent: 'center',
                        }}>
                            <img src={FlashIcon} />
                        </Box>
                        <Button
                            onClick={() => {
                                switchBnbNetwork?.(process.env.REACT_APP_CHAIN_MAIN)
                            }}
                            sx={{
                                width: '100%',
                                height: '53px',
                                marginTop: '50px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                borderRadius: '10px',
                                border: '1px solid #FFF',
                                paddingY: '10px',
                                background: 'rgba(255, 255, 255, 0.10)'
                            }}>
                            <img src={NetworkImg} />
                            <Typography sx={{
                                fontFamily: 'Inter',
                                fontSize: 16,
                                fontStyle: 'normal',
                                fontWeight: '600',
                                color: 'white',
                                marginLeft: '10px'
                            }}>
                                Switch Network to Continue
                            </Typography>
                        </Button>

                        <Button
                            onClick={() => {
                                switchBnbNetwork?.(process.env.REACT_APP_CHAIN_MAIN)
                            }}
                            sx={{
                                width: '100%',
                                marginTop: '32px',
                                borderRadius: '10px',
                                paddingY: '10px',
                                background: '#FBBF04',
                                height: '53px',
                            }}>
                            <Typography sx={{
                                fontFamily: 'Inter',
                                fontSize: 16,
                                fontStyle: 'normal',
                                fontWeight: '600',
                                color: '#151103',
                            }}>
                                Switch to BNB Chain
                            </Typography>
                        </Button>
                        <Button
                            onClick={() => {
                                disconnect();
                                setOpen(false)
                            }}
                            sx={{
                                width: '100%',
                                height: '53px',
                                marginTop: '10px',
                                borderWidth: '1px',
                                borderRadius: '10px',
                                border: '1px solid #FBBF04',
                                paddingY: '10px',
                            }}>
                            <Typography sx={{
                                fontFamily: 'Inter',
                                fontSize: 16,
                                fontStyle: 'normal',
                                fontWeight: '600',
                                color: '#FBBF04',
                            }}>
                                Disconnect Wallet
                            </Typography>
                        </Button>

                    </Box>

                    {/* <Button variant="contained" disableElevation onClick={() => { switchNetwork(); setOpen(false) }}>
                        Change Network in wallet
                    </Button>
                    <Button variant="contained" disableElevation onClick={() => { disconnect(); setOpen(false) }}>
                        Disconnect Wallet
                    </Button> */}
                </Box>
            </Modal>
        </div>
    );
}