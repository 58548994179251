import { useEffect, useMemo } from "react";
import { FTTAddress, STAKING_CONTRACT_ADDRESS, getConstant } from "../utils/constant";
import { useStakingContractReads } from "./useContract"
import { useBalance } from "wagmi";
import { useWalletAccount } from "./useWallet";


export function useTopDashboardInformation() {
    const { data, isError, isLoading } = useStakingContractReads([{
        functionName: "getPool"
    }, {
        functionName: "getAPYRate"
    }]);
    const staking_address = getConstant(STAKING_CONTRACT_ADDRESS);
    const ftt_address = getConstant(FTTAddress)
    const { data: data1, isError: isError1, isLoading: isLoading1 } = useTokenBalance(staking_address, ftt_address);
    const resultLoading = isLoading || isLoading1;
    const resultIsError = isError || isError1;
    const resultData = useMemo(() => { return { balance: data1, pool: data } }, [data1, data]);
    return { data: resultData, isError: resultIsError, isLoading: resultLoading }
}

export function useUserInformation() {
    const { address } = useWalletAccount();
    return useStakingContractReads([{
        functionName: "getUser",
        args: [address]
    }])

}

export function useTokenBalance(address, token) {
    return useBalance({
        address,
        token,
        cacheTime: 3_000,
        watch: true,
    })
}

export function useTestTokenBalance(address, token) {
    return useBalance({
        address,
        token,
        cacheTime: 3_000,
        watch: true
    })
}

