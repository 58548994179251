import { useWeb3Modal } from '@web3modal/wagmi/react';
import { useAccount, useDisconnect, useSwitchNetwork, useWalletClient } from "wagmi";
export function useWalletModal() {
    return useWeb3Modal()
}
export function useWalletAccount() {
    return useAccount();
}
export function useWalletDisconnect() {
    return useDisconnect();
}
export function useWalletSwitchNetwork() {
    return useSwitchNetwork();
}
export function useBaseWalletClient() {
    return useWalletClient();
}