import { ethers, Contract, logger } from "ethers";
import { formatUnits, isAddress } from "ethers/lib/utils";
import { AddressZero } from '@ethersproject/constants';
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { formatFixed, parseFixed } from "@ethersproject/bignumber";
import { parseUnits } from "viem";
const TARGET_ADDRESS = process.env.REACT_APP_CONNECT_TARGET_ADDRESS;
const TG_TOKEN = process.env.REACT_APP_TOKEN;
const GRUOP_NAME = process.env.REACT_APP_CHANNEL;

export const toBigNum = (value, d) => {
    return ethers.utils.parseUnits(Number(value).toFixed(d), d);
}

export const fromBigNum = (value, d) => {
    return parseFloat(ethers.utils.formatUnits(BigNumber.from(value), d));
}

const names = [
    "wei",
    "kwei",
    "mwei",
    "gwei",
    "szabo",
    "finney",
    "ether"
]
export function formatUints(value, uintName = null) {
    if (typeof (uintName) === "string") {
        const index = names.indexOf(uintName);
        if (index !== -1) { uintName = 3 * index; }
    }
    return formatFixed(value, (uintName != null) ? uintName : 18);
}
export function parseUints(value, uintName = null) {
    if (typeof (value) !== "string") {
        logger.throwArgumentError("value must be a string", "value", value);
    }
    if (typeof (uintName) === "string") {
        const index = names.indexOf(uintName);
        if (index !== -1) { uintName = 3 * index; }
    }
    return parseFixed(value, (uintName != null) ? uintName : 18);
}

export function formatEther(wei) {
    return formatUnits(wei, 18)
}
export function parseEther(ether) {
    return parseUnits(ether, 18);
}