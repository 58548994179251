import React, { useMemo } from 'react'
import { useState, useEffect } from 'react';
import { Card, Header, Layout, LeftBar } from '../../components';
import imgDemo from '../../assets/images/demo.png';
import Staking from './components/Staking';
import Token from './components/Token';
import { stakingContract } from "../../contracts";
import { useTopDashboardInformation } from '../../hooks/useDashbord';
import {
    Grid,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import { useAccount, useWaitForTransaction } from "wagmi";
import styles from "./style.module.css";
import { Button } from "../../components";
import { useWeb3Modal } from '@web3modal/wagmi/react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { formatEther, parseEther, toBigNum } from '../../utils/index';
import Paper from '@mui/material/Paper';
import { useUserInformation } from "../../hooks/useDashbord";
import CircularProgress from '@mui/material/CircularProgress';
import { useStake, useUnstake } from "../../hooks/useStaking";
import { toast } from "react-toastify";
import { useWalletClient } from 'wagmi';
import { STAKING_CONTRACT_ADDRESS, convertPlan, getConstant, getPlanMonth } from "../../utils/constant";
import { useAllowance, useApprove } from '../../hooks/useApprove';
import StakeButton from '../../components/StakeButton';
import userimg from "../../assets/images/Ellipse 1.png";

const Home = () => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const [currentAmount, setCurrentAmount] = useState(0);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [minute, setMinute] = useState(0);
    const [second, setSecond] = useState(0);
    const [timeLeft, setTimeLeft] = useState({});
    const [filter, setFilter] = useState(-1);
    const [remainedTime, setRemainedTime] = useState(0);
    const [totalRewardDistributed, setTotalRewardDistributed] = useState();


    const { data, isError, isLoading } = useTopDashboardInformation();
    const [isEarlier, setIsEarlier] = useState(false);

    const topInformation = useMemo(() => {
        if (data && data.pool && data.balance) {
            const balance = data?.balance.formatted;

            const profit = (data?.pool[0].status === "success") ? data?.pool[0]?.result?.totalRewardDistributed.toString() && formatEther(data?.pool[0]?.result?.totalRewardDistributed.toString()) : 0;
            const stakers = (data?.pool[0].status === "success") ? parseInt(data?.pool[0]?.result?.totalStakers) : 0;
            const apyRate = (data?.pool[1].status === "success") ? parseInt(data?.pool[1]?.result[3]) / 100 : 0;
            return { balance, profit, apyRate, stakers }
        } else {
            return {
                balance: 0,
                profit: 0,
                apyRate: 0,
                stakers: 0
            }
        }
    }, [data])


    const [isClaimFTT, setIsClaimFTT] = useState(true);
    const [isApproved, setIsApproved] = useState(false);
    // const [isClaimMonth, setIsClaimMonth] = useState(true);
    const { open } = useWeb3Modal();
    const { isConnected, address } = useAccount();
    const [bonusOptions, setBonusOptions] = useState(-1);
    const [amount, setAmount] = useState(0);
    const { data: userData, isError: userError, isLoading: userLoading } = useUserInformation();
    const [stakeId, setStakeId] = useState(0);


    const { data: dataUnStake, isLoading: unStakeIsLoading, isSuccess: unStakeSuccess, write: unStakeWrite } = useUnstake([isClaimFTT, stakeId.toString()]);

    const { data: approveData, write: approveWrite } = useApprove([getConstant(STAKING_CONTRACT_ADDRESS), parseEther(amount.toString())])

    const { data: allowanceData } = useAllowance()
    useEffect(() => {
        let allowData = formatEther(allowanceData ? allowanceData.toString() : 0)
        if (allowData >= amount)
            setIsApproved(true)
        else
            setIsApproved(false)
    }, [allowanceData, amount])


    const unStakeWait = useWaitForTransaction({
        hash: dataUnStake?.hash,
        onSuccess(data) {
            window.location.reload();
        },
    })


    const approveWait = useWaitForTransaction({
        hash: approveData?.hash,
        onSuccess(data) {
            setTimeout(() => {
            }, 1000)
        },
    })
    const convertTimeStamp = (timeStamp) => {
        var date = new Date(parseInt(timeStamp) * 1000)
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
        return formattedDate
    }


    const stakingList = useMemo(() => {
        if (userData?.length && userData[0].status === "success") {
            const totalDepositforUser = formatEther(userData[0].result.totalDeposit.toString());
            const profitForUser = formatEther(userData[0].result.totalRewardDistributed.toString());
            setTotalRewardDistributed(profitForUser);
            const stakingList = userData[0].result.stakingList.map((item) => {
                if (item.unstaked === false && isEarlier == false) {
                    setIsEarlier(true)
                    let timeDiffer = parseInt(item.startTime) * 1000 + 8.64e7 * convertPlan(parseInt(item.plan)) - Date.now();
                    setRemainedTime(timeDiffer)
                    setCurrentAmount(formatEther(item.stakeAmount.toString()))
                }
                return {
                    finishTime: parseInt(item.finishedTime),
                    profit: formatEther(item.profit.toString()),
                    stakeAmount: formatEther(item.stakeAmount.toString()),
                    unstaked: item.unstaked,
                    startTime: convertTimeStamp(item.startTime),
                    plan: parseInt(item.plan)
                }
            });

            return {
                totalDepositforUser,
                profitForUser,
                stakingList
            }
        } else {
            return {
                totalDepositforUser: 0,
                profitForUser: 0,
                stakingList: []
            }
        }
    }, [userData])

    useEffect(() => {
        const timer = setTimeout(() => {
            if (remainedTime > 0) {
                let tmpday = Math.floor(remainedTime / 864e5)
                let tmphour = Math.floor((remainedTime % 864e5) / 36e5)
                let tmpmin = Math.floor(((remainedTime % 864e5) % 36e5) / 6e4)
                let tmpsec = Math.floor(((remainedTime % 864e5) % 36e5) % 6e4 / 1000);
                setDay(tmpday)
                setHour(tmphour)
                setMinute(tmpmin)
                setSecond(tmpsec)
                setRemainedTime(remainedTime - 1000)
            }
        }, 1000);
    });

    const rows = useMemo(() => {
        if (stakingList?.stakingList) {
            return stakingList?.stakingList?.map((item) => {
                return createData(item.stakeAmount + "FTT", item.startTime, getPlanMonth(item.plan), item.profit + "FTT", item.unstaked)
            })
        }
        return [];
    }, [stakingList?.stakingList])


    const connectWallet = () => {
        open()
    }


    const onApprove = () => {

        if (bonusOptions == -1) {
            toast.warning("Bonus Lock should be selected!");
            return;
        }
        if (amount === 0) {
            toast.warning("Amount to stake should not be zero!");
            return;
        }
        approveWrite && approveWrite();
        //   
    }

    const unstake = (index) => {
        setStakeId(index);
        unStakeWrite && unStakeWrite()
    }

    function createData(amount, date, plan, reward, unstaked) {
        return { amount, date, plan, reward, unstaked };
    }

    const checkFilterDate = (date) => {
        var tmpDate1 = new Date(date);
        var tmpDate2 = new Date()
        var tmp = tmpDate2.getTime() - tmpDate1.getTime();
        return filter == -1 ? true : (tmp / 864e5) < filter;
    }

    const [mobile, setMobile] = useState();
    useEffect(() => {


        window.addEventListener("resize", () => {
            setMobile(window.innerWidth);
        })
    })

    useEffect(() => {
        setMobile(window.innerWidth);
    }, [])


    return (
        <Layout name="headername">
            <Header mobile={mobile} rightimg={userimg} userid="0x71C76......34Db76F" mainHeading="Staking" />
            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                <Card label="Total Value Locked" value={`${Math.floor(topInformation?.balance * 100) / 100} FTT`} isLoading={isLoading} />
                <Card label="Profit " value={`${Math.floor(topInformation?.profit * 100) / 100} FTT`} isLoading={isLoading} />
                <Card label="APY Rate" value={`${topInformation?.apyRate} %`} isLoading={isLoading} />
                <Card label="Stakers" value={`${topInformation?.stakers} `} isLoading={isLoading} />
            </Grid>
            <Grid
                container sx={{ marginTop: '5px' }}
                spacing={2}>
                <>
                    <Grid item lg={5} xs={12} sx={{ paddingRight: "0px" }}>
                        <div className={styles.ps_container1}>
                            <h4 className='text-white' style={{ fontSize: '20px' }}>Lock Period</h4>
                            <div className={styles.innerBox}>
                                <p className='text-light text-center opacity-50 mb-0'>{weekday[new Date().getDay()]}</p>
                                <p className='text-light text-center mb-0' >{new Date().toLocaleDateString('en-us', options)}</p>

                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '30px'
                                }}>
                                    <div className={styles.time}>
                                        <h2>{day}</h2>
                                        <p>Day</p>
                                    </div>
                                    <div className={styles.time}>
                                        <h2>{hour}</h2>
                                        <p>Hour</p>
                                    </div>
                                    <div className={styles.time}>
                                        <h2>{minute}</h2>
                                        <p>Mins</p>
                                    </div>
                                    <div className={styles.time}>
                                        <h2>{second}</h2>
                                        <p>Sec</p>
                                    </div>
                                </Box>

                                <div className='my-4'>
                                    <p className='text-white text-center'>
                                        {currentAmount} FTT
                                    </p>
                                </div>


                            </div>

                        </div>
                    </Grid></>
                <Staking totalRewardDistributed={totalRewardDistributed} setFilter={setFilter} filter={filter} />
            </Grid>
            <Grid
                container
                sx={{ marginTop: '20px', marginBottom: '30px', gap: { sm: '17px', lg: '0px' }, flexDirection: { sm: 'column-reverse', lg: 'row' } }}
            >
                <>
                    <Grid
                        lg={7}
                        xs={12}
                        className={styles.amount_grid_container}
                    >

                        <div className={styles.amountContainer}>
                            <Box display="flex" gap={'20px'} mb="20px" flexDirection={{ xs: "column", md: "row" }}>
                                <Box sx={{ flex: 1, padding: "" }}>
                                    <h5 className="text-white">Amount to Stake</h5>
                                    <FormControl fullWidth sx={{ marginTop: "10px" }}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                width: '100%',
                                                alignItems: "center",
                                                padding: "5px 10px ",
                                                justifyContent: "space-between",
                                                border: "1px solid #FFFFFF33",
                                                height: "64px",
                                                borderRadius: "10px",
                                            }}
                                        >
                                            <input className={styles.inputnumber} placeholder="" type="number" style={{ width: '100%' }} defaultValue={"0"}
                                                onChange={(e) => {
                                                    if (e.target.value != "") {
                                                        setAmount(e.target.value)
                                                    }
                                                    // setAmount(e.target.value != null && e.target.value)
                                                }}
                                            />
                                            {/* <Typography className={styles.inputnumber}>0</Typography> */}

                                            {/* <Box style={{ display: 'flex' }}>
                    <Box className={styles.inputbtn} component="button">
                      BALANCE
                    </Box>
                    <Box className={styles.inputbtn} component="button">
                      STAKED
                    </Box>
                  </Box> */}
                                        </Box>
                                    </FormControl>
                                </Box>
                                <Box sx={{ flex: 1, padding: "" }}>
                                    <h5 className="text-white">Bonus Lock Options</h5>
                                    <FormControl fullWidth sx={{ marginTop: "10px" }}>
                                        {/* <InputLabel id="demo-simple-select-label" sx={{ color: "white" }}>
                Select Lock
              </InputLabel> */}
                                        <Select
                                            value={bonusOptions}
                                            disableUnderline
                                            fullWidth
                                            variant="standard"
                                            sx={{
                                                padding: "10px 15px ",
                                                fontSize: "18px",
                                                width: "100%",
                                                fontWight: 500,
                                                borderRadius: "10px",
                                                color: "#F2FFE1",
                                                background:
                                                    "linear-gradient(180deg, rgba(251, 191, 4, 0) 0%, rgba(251, 191, 4, 0.05) 100%)",
                                                border: "1px solid rgba(255, 255, 255, 0.2)",

                                                ".MuiSelect-icon": {
                                                    color: "white",
                                                },
                                            }}
                                        >
                                            <MenuItem onClick={() => setBonusOptions(-1)} value={-1}> Select Lock</MenuItem>
                                            <MenuItem onClick={() => setBonusOptions(0)} value={0}>30 Days - 0.15% Reward</MenuItem>
                                            <MenuItem onClick={() => setBonusOptions(1)} value={1}>90 Days - 0.50% Reward</MenuItem>
                                            <MenuItem onClick={() => setBonusOptions(2)} value={2}>180 Days - 1.4% Reward</MenuItem>
                                            <MenuItem onClick={() => setBonusOptions(3)} value={3}>365 Days - 3% Reward</MenuItem>
                                        </Select>
                                        {/* <Select
                                // sx={{ color: 'white' }}
                                sx={{
                                    borderColor: 'white',
                                    '.MuiSelect-icon': {
                                        color: 'white'
                                    },
                                    ".MuiSelect-outlined": {
                                        color: 'white',
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                        borderColor: "white"
                                    }
                                }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Lock"
                            >
                                <MenuItem value={10}>Ten</MenuItem>
                                <MenuItem value={20}>Twenty</MenuItem>
                                <MenuItem value={30}>Thirty</MenuItem>
                            </Select> */}
                                    </FormControl>
                                </Box>
                            </Box>

                            <Grid container spacing={2}>
                                {
                                    <Grid item xs={12} md={6}>
                                        <Button disabled={!isClaimFTT} label="Claim FTT Token" onClick={() => {
                                            setIsClaimFTT(true)
                                        }} />
                                    </Grid>
                                }
                                <Grid item xs={12} md={6}>
                                    <Button label="Claim USDT Token" disabled={isClaimFTT} onClick={() => {
                                        setIsClaimFTT(false)
                                    }} />
                                </Grid>
                                {/* <Grid item xs={12} md={6} sx={{ marginX: "auto" }}>
						<Button label="Claim [Token of month]" disabled={isClaimMonth} onClick={() => {
							setIsClaimMonth(!isClaimMonth)
						}} />
					</Grid> */}
                            </Grid>
                            {
                                rows.length == 0 ?
                                    <></>
                                    : <TableContainer component={Paper}
                                        sx={{
                                            marginTop: 5,
                                            display: "flex",
                                            width: '100%',
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            border: "1px solid #363636",
                                            backgroundColor: 'transparent',
                                            borderRadius: "10px",
                                        }}>
                                        <Table aria-label="simple table" >
                                            <TableHead >
                                                <TableRow >
                                                    <TableCell sx={{ color: "white", fontSize: 12 }}>Stake Amount</TableCell>
                                                    <TableCell sx={{ color: "white", fontSize: 12 }}>Date</TableCell>
                                                    <TableCell sx={{ color: "white", fontSize: 12 }}>Plan</TableCell>
                                                    <TableCell sx={{ color: "white", fontSize: 12 }}>Reward</TableCell>
                                                    <TableCell sx={{ color: "white", fontSize: 12 }}>CTA</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    isLoading ? (
                                                        <CircularProgress />
                                                    ) : (
                                                        rows.filter((item) =>
                                                            checkFilterDate(item.date)

                                                        ).map((row, index) => (
                                                            <TableRow

                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell sx={{ color: "white", fontSize: 16 }} align="left">{Math.floor(parseFloat(row.amount) * 100) / 100}FTT</TableCell>
                                                                <TableCell sx={{ color: "white", fontSize: 14 }} align="left">{row.date}</TableCell>
                                                                <TableCell sx={{ color: "white", fontSize: 12 }} align="left">{row.plan}</TableCell>
                                                                <TableCell sx={{ color: "white", fontSize: 12 }} align="left">{Math.floor(parseFloat(row.reward) * 1000) / 1000}FTT</TableCell>
                                                                <TableCell>
                                                                    <Button onClick={() => {
                                                                        unstake(index);
                                                                    }} height={32} unStaked={row.unstaked} bg={"#FBBF04"} color={"#151103"} fontSize={10} sx={{ width: '60px', color: 'black' }} label={"Unstake"}>
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))
                                                    )
                                                }

                                            </TableBody>
                                        </Table >
                                    </TableContainer >
                            }


                            {
                                isConnected ?
                                    isApproved ?
                                        <StakeButton amount={amount} bonusOptions={bonusOptions} setIsApproved={setIsApproved} isApproved={isApproved} />
                                        :
                                        <Box sx={{ marginTop: "20px" }} >
                                            <Button label="Approve" bg="#FBBF04" color={'black'} onClick={() => {
                                                onApprove();
                                            }} />
                                        </Box >

                                    :
                                    <Box sx={{ marginTop: "20px" }}>
                                        <Button label="Connect Wallet" bg="#FBBF04" color={'black'} onClick={() => { connectWallet() }} />
                                    </Box>
                            }
                        </div >
                    </Grid >
                </>
                <Token />
            </Grid>
            <br className='mo-show-only' />
            <br className='mo-show-only' />
        </Layout >
    )
}

export default Home;
