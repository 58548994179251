
import { ethers } from "ethers";

// import Contrats from "./contracts/4002.json";
import Contrats from "./contracts/1.json";
import { fromBigNum } from "../utils";
const supportChainId = 1;
const TOTALSTAKINGTOKENAMOUNT = 650000000000;

const RPCS = {
    1: "https://mainnet.infura.io/v3/49a547b1f17a417f8b7fe8dc8d33308e",
    56: "https://bsc-dataseed.binance.org/",
}

const providers = {
    1: new ethers.providers.JsonRpcProvider(RPCS[1]),
    56: new ethers.providers.JsonRpcProvider(RPCS[56])
}

const stakingContract = new ethers.Contract(Contrats.staking.address, Contrats.staking.abi, providers[supportChainId]);

export default stakingContract
